import { Box, Heading, Text } from "@chakra-ui/react";

const TitleText = ({ title, text, children }) => {
  return (
    <Box mb="50px">
      <Heading
        as="h2"
        color="#333"
        fontSize={["18px", "", "22px", ""]}
        fontWeight="bold"
        mb="20px"
      >
        {title}
      </Heading>

      {children}
    </Box>
  );
};

export default TitleText;
