import { Box, Flex, Text, Grid, Image } from "@chakra-ui/react";
import { CustomButton, Luhah as L } from "components/shared";
import { Link } from "react-router-dom";

const Luhah = ({
  charsAr,
  charsEn,
  numbers,
  location,
  date,
  price,
  underBidding,
  id,
  type,
}) => {
  const Info = ({ name, value, ...props }) => {
    return (
      <Box bg="#f7f7f7" p="5px" display="flex" alignItems="center" {...props}>
        <Text fontSize={["15px", "16px", "", ""]}>
          {name}{" "}
          <Text as="span" fontWeight="bold">
            {value}
          </Text>
        </Text>
      </Box>
    );
  };

  return (
    <Flex
      alignItems="center"
      boxShadow="0 0 10px 0 #ccc"
      p="10px"
      flexWrap={["wrap", "nowrap", "", ""]}
    >
      <L
        charsEn={charsEn}
        charsAr={charsAr}
        numbers={numbers}
        type={type}
        w={["100%", "250px", "343px", ""]}
        me={["0", "10px", "30px", ""]}
      />

      <Grid
        gridTemplateColumns={[
          "repeat(2,2fr)",
          "repeat(4,130px)",
          "repeat(4,156px)",
          "",
        ]}
        gap={["15px", "10px", "30px", ""]}
        mt={["15px", "", "0", ""]}
        w={["100%", "auto", "", ""]}
      >
        <Info name="المدينة" value={location} />
        <Info name="التاريخ" value={date} />
        <Info
          name="السعر"
          value={underBidding == 1 ? "على السوم" : `${price} ريال`}
        />

        <CustomButton
          as={Link}
          to={`show-adds/${id}`}
          name="مشاهدة الإعلان"
          w="auto"
          display="grid"
          h="100%"
          placeItems="center"
        />
      </Grid>
    </Flex>
  );
};

export default Luhah;
