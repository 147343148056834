import { useState } from "react";
import { Box, Spinner, Text, Textarea, useToast } from "@chakra-ui/react";
import { CustomButton, CustomToast } from "components/shared";
import { reportListing } from "apis/lu7ah";
import { useMutation } from "react-query";

const ReportModal = ({ id, setShowReportModal }) => {
  const [reportMessage, setReportMessage] = useState(null);
  const toast = useToast();

  const { mutate, isLoading } = useMutation(reportListing, {
    onSuccess: () => {
      toast({
        render: (props) => {
          return (
            <CustomToast
              title="الإبلاغ عن الإعلان "
              description="تم الإبلاغ عن الإعلان بنجاح"
              status="success"
              onClose={props.onClose}
            />
          );
        },
      });
      setShowReportModal(false);
    },

    onError: () => {
      toast({
        render: (props) => {
          return (
            <CustomToast
              title="الإبلاغ عن الإعلان "
              description="حدث خطأ ما برجاء اعادة المحاولة"
              status="error"
              onClose={props.onClose}
            />
          );
        },
      });
    },
  });

  return (
    <Box>
      <Text mb="5px">ما سبب الإبلاغ؟</Text>
      <Textarea
        placeholder="اكتب رسالتك هنا"
        minH="150px"
        resize="none"
        onChange={(e) => setReportMessage(e.target.value)}
        _focus={{}}
        _active={{}}
        _focusVisible={{ boxShadow: "none" }}
      ></Textarea>
      <CustomButton
        name="إرسال"
        mt="20px"
        display="flex"
        alignItems="center"
        disabled={isLoading}
        loading={isLoading && <Spinner me="5px" />}
        onClick={() => {
          if (reportMessage == null || reportMessage?.trim() == "") {
            toast({
              render: (props) => {
                return (
                  <CustomToast
                    title="الإبلاغ عن الإعلان "
                    description="يجب إبلاغنا بالمشكلة أولا"
                    status="error"
                    onClose={props.onClose}
                  />
                );
              },
            });
          } else {
            mutate({ id, message: reportMessage?.trim() });
          }
        }}
      />
    </Box>
  );
};

export default ReportModal;
