import { useState, memo } from "react";
import { Box, Flex, Text, Spinner, useToast } from "@chakra-ui/react";
import {
  CustomButton,
  CustomInput,
  CustomModal,
  CustomToast,
} from "components/shared";
import { useMutation } from "react-query";
import { payment } from "apis/payment";
import PayModal from "./pay-modal";
import { useNavigate } from "react-router-dom";

const AddPriceModal = ({ id, isShow }) => {
  const toast = useToast();

  const [price, setPrice] = useState(0);
  const [pay, setPay] = useState(false);

  const navigate = useNavigate();

  const { mutate, isLoading } = useMutation(payment, {
    onSuccess: (e) => {
      navigate("/pay", {
        state: {
          urlRequest: e?.data?.redirect_url,
          commission: parseFloat(price * 0.015).toFixed(2),
        },
      });
    },
    onError: () => {
      toast({
        render: (props) => {
          return (
            <CustomToast
              title="استكمال الدفع "
              description="حدث خطأ ما فى استكمال عملية الدفع"
              status="error"
              onClose={props.onClose}
            />
          );
        },
      });
    },
  });

  const Details = ({
    name,
    value,
    withBorder,
    isBold,
    nameColor,
    ...props
  }) => {
    return (
      <Flex
        justifyContent="space-between"
        alignItems="center"
        fontWeight={isBold ? "bold" : "normal"}
        borderBottom={withBorder ? "1px solid #e9e9e9" : "none"}
        py="10px"
        {...props}
      >
        <Text color={nameColor}>{name}</Text>
        <Text>{value}</Text>
      </Flex>
    );
  };

  return (
    <Box>
      <CustomInput
        label="أدخل سعر البيع"
        type="number"
        isLabelBold
        onChange={(e) => setPrice(e.target.value)}
      />
      <Details
        name="المبلغ"
        value={price > 0 ? parseFloat((price * 0.015) / 1.15).toFixed(2) : 0}
        withBorder
        mt="15px"
      />

      <Details
        name="الضريبة"
        value={
          price > 0 ? parseFloat(((price * 0.015) / 1.15) * 0.15).toFixed(2) : 0
        }
        withBorder
        mt="15px"
      />

      <Details
        name="العمولة شامل الضريبة"
        value={price > 0 ? parseFloat(price * 0.015).toFixed(2) : 0}
        mb="15px"
        isBold
        nameColor="text.darkBlue"
      />

      {!isShow && (
        <>
          <CustomButton
            display="flex"
            alignItems="center"
            justifyContent="center"
            loading={isLoading && <Spinner me="5px" />}
            disabled={isLoading}
            name="استكمال عملية الدفع"
            onClick={() => {
              if (price * 0.015 <= 0) {
                toast({
                  render: (props) => {
                    return (
                      <CustomToast
                        title="ادخال سعر  "
                        description="يجب ان تكون قيمة المبلغ اكبر من الصفر"
                        status="error"
                        onClose={props.onClose}
                      />
                    );
                  },
                });
              } else if (price * 0.015 < 15) {
                toast({
                  render: (props) => {
                    return (
                      <CustomToast
                        title="قيمة العمولة"
                        description="عمولتك أقل من 15 ريال، موقع لوحة يتحمل العمولة عنك."
                        status="warning"
                        onClose={props.onClose}
                      />
                    );
                  },
                });
              } else {
                mutate({
                  id,
                  commission: parseFloat(price * 0.015).toFixed(2),
                  after_completion_action: `${process.env.REACT_APP_BASE_URL}/user`,
                  use_v2: true,
                });
              }
            }}
          />
          <CustomModal
            show={pay}
            setShow={setPay}
            title="اتمام عملية الدفع"
            overflow="outside"
          >
            <PayModal price={price > 0 ? price * 0.015 : 0} />
          </CustomModal>
        </>
      )}
    </Box>
  );
};

export default memo(AddPriceModal);
