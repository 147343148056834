import { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Text,
  PinInput,
  PinInputField,
  Grid,
  Input,
  Tooltip,
  Button,
  Image,
  Spinner,
  useToast,
} from "@chakra-ui/react";
import {
  CustomInput,
  CustomSelect,
  CustomButton,
  ErrorMsg,
  CustomToast,
} from "components/shared";
import { Formik, Form } from "formik";
import { paymentValidation } from "validations/payment";
import { SlQuestion } from "react-icons/sl";
import { BsCreditCard2Front } from "react-icons/bs";
import { months, years } from "components/shared/selections";
import { useMutation } from "react-query";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import visa from "assets/images/visa2.svg";
import mada from "assets/images/mada.svg";
import { BsCheckLg } from "react-icons/bs";

const Payment = () => {
  const PaymentMethod = ({ icon, name, img, isChecked, imgW, ...props }) => {
    return (
      <Flex
        bg={isChecked ? "white" : "#f3f3f3"}
        py="15px"
        alignItems="center"
        justifyContent="center"
        borderRadius="15px"
        cursor="pointer"
        border="4px solid #939393"
        pos="relative"
        {...props}
      >
        {/* <Text color="white">{name}</Text> */}
        <Image src={img} w={imgW} />
        {isChecked && (
          <Grid
            placeItems="center"
            w="25px"
            h="25px"
            borderRadius="50%"
            bg="bg.darkBlue"
            pos="absolute"
            top="-12px"
            right="-12px"
          >
            <Box as={BsCheckLg} color="white" fontSize="12px" />
          </Grid>
        )}
      </Flex>
    );
  };

  const paymentTypes = [
    {
      type: "C",
      name: "credit card",
      img: visa,
      width: "80%",
    },
    {
      type: "D",
      name: "mada",
      img: mada,
      width: "75%",
    },
  ];

  const [isValid, setIsValid] = useState(false);
  const [paymentType, setPaymentType] = useState("C");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const toast = useToast();

  const handleSelectYear = (e, formik) => {
    setYear(e);
    formik.setFieldValue("year", e.id);
  };

  const handleSelectMonth = (e, formik) => {
    setMonth(e);
    formik.setFieldValue("month", e.id);
  };

  const location = useLocation();

  const urlRequest = location?.state?.urlRequest;
  const commission = location?.state?.commission;

  const pay = (data) => {
    return axios.post(urlRequest, data);
  };

  const { isLoading, mutate } = useMutation(pay, {
    onSuccess: (result) => {
      toast({
        render: (props) => {
          return (
            <CustomToast
              title="الانتقال الى البنك"
              description="لا تغلق الصفحة سيتم تحويلكم الى البنك"
              status="success"
              onClose={props.onClose}
            />
          );
        },
      });
      window.location.replace(result?.data?.redirectUrl);
    },
    onError: () => {
      toast({
        render: (props) => {
          return (
            <CustomToast
              title={"حدث خطأ"}
              description="حدث خطأ ما برجاء اعادة المحاولة "
              status="error"
              onClose={props.onClose}
            />
          );
        },
      });
    },
  });

  useEffect(() => {
    if (urlRequest) {
      setIsValid(true);
    }
  }, [urlRequest]);

  if (!isValid) {
    return (
      <Grid placeItems="center" minH="60vh" fontSize="30px">
        لا يوجد بيانات لعرضها
      </Grid>
    );
  }

  return (
    <Box px={["0", "15px", "0", ""]}>
      <Box
        dir="rtl"
        maxW="800px"
        mb="30px"
        //   border="1px solid black"
        borderRadius="5px"
        boxShadow="0 0 10px 6px #efefef"
        p="15px"
        mx={["10px", "auto", "auto", ""]}
      >
        <Box mb="40px">
          <Text
            fontWeight="bold"
            pos="relative"
            mb="40px"
            _after={{
              content: `""`,
              pos: "absolute",
              bottom: "-8px",
              w: "20px",
              h: "1px",
              bg: "#ced4da",
              right: "0",
            }}
          >
            طريقةالدفع
          </Text>
          <Grid
            gridTemplateColumns={["repeat(2,1fr)", "repeat(4,1fr)", "", ""]}
            gap="10px"
          >
            {paymentTypes?.map((e) => {
              return (
                <PaymentMethod
                  name={e.name}
                  icon={BsCreditCard2Front}
                  isChecked={e.type == paymentType}
                  img={e.img}
                  imgW={e.width}
                  onClick={() => {
                    setPaymentType(e.type);
                  }}
                />
              );
            })}

            {/* <PaymentMethod name="مدى" icon={BsCreditCard2Front} /> */}
          </Grid>
        </Box>
        <Formik
          initialValues={{
            name: "",
            numberCart: "",
            month: "",
            year: "",
            cvv: "",
          }}
          validationSchema={paymentValidation}
          onSubmit={(values) => {
            mutate({
              cardNumber: values.numberCart,
              expireMonth: values.month,
              expireYear: values.year,
              cvv: values.cvv.toString(),
              username: values.name,
              cardType: paymentType,
            });
          }}
        >
          {(formik) => {
            return (
              <Form>
                <Box mb="30px">
                  <CustomInput
                    label="الاسم على الكارت"
                    type="text"
                    value={formik.values.name}
                    onChange={formik.handleChange("name")}
                    onBlur={formik.handleBlur("name")}
                  />
                  {formik.errors.name && formik.touched.name && (
                    <ErrorMsg text={formik.errors.name} />
                  )}
                </Box>
                <Box>
                  <Text mb="5px">رقم الكارت</Text>
                  <Flex
                    alignItems="center"
                    justifyContent="space-between"
                    border="1px solid #ced4da"
                    borderRadius="5px"
                    px={["5px", "15px", "", ""]}
                    py="4px"
                    flexDirection="row-reverse"
                  >
                    <Grid
                      gap="0"
                      gridTemplateColumns="repeat(19,15px)"
                      dir="ltr"
                    >
                      <PinInput
                        placeholder="0"
                        value={formik.values.numberCart}
                        onChange={formik.handleChange("numberCart")}
                        onBlur={formik.handleBlur("numberCart")}
                      >
                        <PinInputField
                          type="number"
                          border="none"
                          w="15px"
                          _focusVisible={{ border: "none" }}
                        />
                        <PinInputField
                          type="number"
                          border="none"
                          w="15px"
                          _focusVisible={{ border: "none" }}
                        />
                        <PinInputField
                          type="number"
                          border="none"
                          w="15px"
                          _focusVisible={{ border: "none" }}
                        />
                        <PinInputField
                          type="number"
                          border="none"
                          w="15px"
                          _focusVisible={{ border: "none" }}
                        />

                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          mx="5px"
                          border="none"
                          w="initial"
                        >
                          -
                        </Box>

                        <PinInputField
                          type="number"
                          border="none"
                          w="15px"
                          _focusVisible={{ border: "none" }}
                        />
                        <PinInputField
                          type="number"
                          border="none"
                          w="15px"
                          _focusVisible={{ border: "none" }}
                        />
                        <PinInputField
                          type="number"
                          border="none"
                          w="15px"
                          _focusVisible={{ border: "none" }}
                        />
                        <PinInputField
                          type="number"
                          border="none"
                          w="15px"
                          _focusVisible={{ border: "none" }}
                        />

                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          mx="5px"
                          border="none"
                          w="initial"
                        >
                          -
                        </Box>

                        <PinInputField
                          type="number"
                          border="none"
                          w="15px"
                          _focusVisible={{ border: "none" }}
                        />
                        <PinInputField
                          type="number"
                          border="none"
                          w="15px"
                          _focusVisible={{ border: "none" }}
                        />
                        <PinInputField
                          type="number"
                          border="none"
                          w="15px"
                          _focusVisible={{ border: "none" }}
                        />
                        <PinInputField
                          type="number"
                          border="none"
                          w="15px"
                          _focusVisible={{ border: "none" }}
                        />

                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          mx="5px"
                          border="none"
                          w="initial"
                        >
                          -
                        </Box>

                        <PinInputField
                          type="number"
                          border="none"
                          w="15px"
                          _focusVisible={{ border: "none" }}
                        />
                        <PinInputField
                          type="number"
                          border="none"
                          w="15px"
                          _focusVisible={{ border: "none" }}
                        />
                        <PinInputField
                          type="number"
                          border="none"
                          w="15px"
                          _focusVisible={{ border: "none" }}
                        />
                        <PinInputField
                          type="number"
                          border="none"
                          w="15px"
                          _focusVisible={{ border: "none" }}
                        />
                      </PinInput>
                    </Grid>
                  </Flex>
                  {formik.errors.numberCart && formik.touched.numberCart && (
                    <ErrorMsg text={formik.errors.numberCart} />
                  )}
                </Box>
                <Grid
                  gridTemplateColumns={[
                    "repeat(1,1fr)",
                    "repeat(3,1fr)",
                    "",
                    "",
                  ]}
                  gap="10px"
                  my="30px"
                >
                  <Box>
                    <CustomSelect
                      label="الشهر"
                      colorLabel="black"
                      placeholder="اختر شهر"
                      py="9px"
                      selections={months}
                      select={month}
                      handleSelect={(e) => {
                        handleSelectMonth(e, formik);
                      }}
                      maxMinueH="200px"
                    />
                    {formik.errors.month && (
                      <ErrorMsg text={formik.errors.month} />
                    )}
                  </Box>

                  <Box>
                    <CustomSelect
                      label="السنه"
                      colorLabel="black"
                      placeholder="اختر سنة"
                      py="9px"
                      selections={years}
                      select={year}
                      handleSelect={(e) => {
                        handleSelectYear(e, formik);
                      }}
                      maxMinueH="200px"
                    />
                    {formik.errors.year && (
                      <ErrorMsg text={formik.errors.year} />
                    )}
                  </Box>

                  <Box>
                    <Text mb="5px">CVV</Text>
                    <Flex
                      border="1px solid #ced4da"
                      borderRadius="5px"
                      alignItems="center"
                      pos="relative"
                    >
                      <Input
                        border="0"
                        maxLength="3"
                        type="number"
                        h="100%"
                        _focusVisible={{ boxShadow: "none" }}
                        py="10px"
                        value={formik.values.cvv}
                        onChange={formik.handleChange("cvv")}
                        onBlur={formik.handleBlur("cvv")}
                      />

                      <Tooltip
                        hasArrow
                        label="Enter cvv number back to your card"
                        bg="red.600"
                      >
                        <Button
                          minW="4px"
                          p="0"
                          w="27px"
                          h="21px"
                          display="grid"
                          borderRadius="0"
                          me="5px"
                          bg="transparent"
                          _hover={{ bg: "none" }}
                        >
                          <Box as={SlQuestion} cursor="pointer" />
                        </Button>
                      </Tooltip>
                    </Flex>
                    {formik.errors.cvv && formik.touched.cvv && (
                      <ErrorMsg text={formik.errors.cvv} />
                    )}
                  </Box>
                </Grid>
                <Grid
                  placeItems="center"
                  borderRadius="5px"
                  p="20px"
                  bg="#f8f9fa"
                  mb="30px"
                >
                  <Text>القيمة الكلية : {commission} ريال</Text>
                </Grid>
                <CustomButton
                  name="ادفع الان"
                  h="45px"
                  type="submit"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  disabled={isLoading}
                  loading={isLoading && <Spinner me="5px" />}
                />
              </Form>
            );
          }}
        </Formik>
      </Box>
    </Box>
  );
};

export default Payment;
