import { Text, Flex } from "@chakra-ui/react";
import { BiErrorCircle } from "react-icons/bi";

const ErrorMsg = ({ text }) => {
  return (
    <Flex alignItems="center" color="red" fontSize="14px" mt="1">
      <BiErrorCircle />
      <Text ml="1">{text}</Text>
    </Flex>
  );
};

export default ErrorMsg;
