import { useState, memo, useContext } from "react";
import { selectionsContext } from "contexts/selections";
import { Box, Text, Grid, Flex, Image } from "@chakra-ui/react";
import { letters, numbers } from "components/shared/selections";
import { CustomSelect, ErrorMsg } from "components/shared";
import lu7ahImage from "assets/images/LU7AH.svg";
import saudiLogo from "assets/images/saudi-logo.png";

const LuhahInfo = memo(
  ({
    type,
    selectNumberType,
    defaultLetter1,
    defaultLetter2,
    defaultLetter3,
    selectNumberOne,
    selectNumberTwo,
    selectNumberThree,
    selectNumberFour,
    setSelectNumberOne,
    setSelectNumberTwo,
    setSelectNumberThree,
    setSelectNumberFour,
    formik,
  }) => {
    const { chars_ar_en, numbers: numbersSelections } =
      useContext(selectionsContext);

    const chars_arabic = Object.keys(chars_ar_en);
    const chars_en = Object.values(chars_ar_en);

    let chars = [];
    let numberSelectionsArray = [];

    numbersSelections?.map((e) => {
      numberSelectionsArray.push({ id: e, name: e });
    });

    chars_arabic?.map((e, i) => {
      chars.push({ name: e, id: e });
    });

    const select = { name: "اختر", id: "" };

    //select letters
    const [selectFirstLetter, setSelectFirstLetter] = useState(
      defaultLetter1 || select
    );
    const [selectSecondLetter, setSelectSecondLetter] = useState(
      defaultLetter2 || select
    );
    const [selectThirdLetter, setSelectThirdLetter] = useState(
      defaultLetter3 || select
    );

    //handle select letters
    const handleSelectFirstLetter = (e) => {
      setSelectFirstLetter(e);
      formik.setFieldValue("letterOne", e.id);
    };

    const handleSelectSecondLetter = (e) => {
      setSelectSecondLetter(e);
      formik.setFieldValue("letterTwo", e.id);
    };

    const handleSelectThirdLetter = (e) => {
      setSelectThirdLetter(e);
      formik.setFieldValue("letterThree", e.id);
    };

    //handle select numbers

    const handleSelectNumberOne = (e) => {
      setSelectNumberOne(e);
      formik.setFieldValue(`numbersList.${0}`, e.id);
    };

    const handleSelectNumberTwo = (e) => {
      setSelectNumberTwo(e);
      formik.setFieldValue(`numbersList.${1}`, e.id);
    };

    const handleSelectNumberThree = (e) => {
      setSelectNumberThree(e);
      formik.setFieldValue(`numbersList.${2}`, e.id);
    };

    const handleSelectNumberFour = (e) => {
      setSelectNumberFour(e);
      formik.setFieldValue(`numbersList.${3}`, e.id);
    };

    return (
      <Flex
        border="1px solid black"
        bg="#f9f9f9"
        mb="25px"
        borderRadius="5px"
        justifyContent="space-between">
        <Grid
          placeItems="center"
          p="5px"
          bg={type?.id == 1 || !type ? "white" : "#27ADF5"}
          borderLeft="2px solid black"
          borderRadius="0 5px 5px 0">
          <Box textAlign="center" textTransform="uppercase" fontSize="14px">
            <Image src={saudiLogo} w="18px" h="18px" m="auto" mb="5px" />
            <Text fontSize="20px" fontWeight="bold">
              k
            </Text>
            <Text fontSize="20px" fontWeight="bold">
              s
            </Text>
            <Text fontSize="20px" fontWeight="bold">
              a
            </Text>
            <Box
              w="8px"
              h="8px"
              borderRadius="50%"
              bg="black"
              m="auto"
              mt="2px"
            />
          </Box>
        </Grid>

        <Box w="100%">
          <Flex
            borderBottom="2px solid black"
            flexWrap={["wrap", "nowrap", "", ""]}>
            <Flex
              alignItems="center"
              justifyContent="center"
              borderEnd={["none", "2px solid black", "", ""]}
              borderBottom={["2px solid black", "none", "", ""]}
              w={["100%", "50%", "", ""]}
              p="10px">
              <Grid
                gridTemplateColumns={["repeat(3,1fr)", "repeat(3,1fr)", "", ""]}
                gap={["5px", "15px", "", ""]}
                w="100%">
                <CustomSelect
                  label="الحرف الاول"
                  placeholder=""
                  select={selectFirstLetter}
                  handleSelect={handleSelectFirstLetter}
                  selections={chars}
                  colorLabel="black"
                />

                <CustomSelect
                  label="الحرف الثانى"
                  placeholder=""
                  select={selectSecondLetter}
                  handleSelect={handleSelectSecondLetter}
                  selections={chars}
                  colorLabel="black"
                />
                <CustomSelect
                  label="الحرف الثالث"
                  placeholder=""
                  select={selectThirdLetter}
                  handleSelect={handleSelectThirdLetter}
                  selections={chars}
                  colorLabel="black"
                />
              </Grid>
            </Flex>

            <Flex
              alignItems="center"
              justifyContent="center"
              w={["100%", "50%", "", ""]}
              p="10px"
              dir="ltr">
              <Box w="100%">
                <Grid
                  gridTemplateColumns={
                    selectNumberType.id == "" || selectNumberType.id == 4
                      ? ["repeat(2,1fr)", "repeat(4,1fr)", "", ""]
                      : selectNumberType.id == 1
                        ? "repeat(1,1fr)"
                        : selectNumberType.id == 2
                          ? "repeat(2,1fr)"
                          : ["repeat(1,190px)", "repeat(3,1fr)", "", ""]
                  }
                  gap={
                    selectNumberType.id == "" || selectNumberType.id == 4
                      ? ["10px", "8px", "15px", ""]
                      : "15px"
                  }
                // dir={
                //   selectNumberType.id == 3 ? ["rtl", "ltr", "", ""] : "ltr"
                // }
                >
                  {selectNumberType.id == "" || selectNumberType.id == 4 ? (
                    <>
                      <Box>
                        <CustomSelect
                          label="الرقم الاول"
                          placeholder=""
                          select={selectNumberOne}
                          handleSelect={handleSelectNumberOne}
                          selections={numberSelectionsArray}
                          colorLabel="black"
                        />
                        {formik?.errors?.numbersList &&
                          formik?.errors?.numbersList[0] && (
                            <ErrorMsg text="اختر رقم" />
                          )}
                      </Box>

                      <Box>
                        <CustomSelect
                          label="الرقم الثانى"
                          placeholder=""
                          select={selectNumberTwo}
                          handleSelect={handleSelectNumberTwo}
                          selections={numberSelectionsArray}
                          colorLabel="black"
                        />

                        {formik?.errors?.numbersList &&
                          formik?.errors?.numbersList[1] && (
                            <ErrorMsg text="اختر رقم" />
                          )}
                      </Box>
                      <Box>
                        <CustomSelect
                          label="الرقم الثالث"
                          placeholder=""
                          select={selectNumberThree}
                          handleSelect={handleSelectNumberThree}
                          selections={numberSelectionsArray}
                          colorLabel="black"
                        />
                        {formik?.errors?.numbersList &&
                          formik?.errors?.numbersList[2] && (
                            <ErrorMsg text="اختر رقم" />
                          )}
                      </Box>
                      <Box>
                        <CustomSelect
                          label="الرقم الرابع"
                          placeholder=""
                          select={selectNumberFour}
                          handleSelect={handleSelectNumberFour}
                          selections={numberSelectionsArray}
                          colorLabel="black"
                        />
                        {formik?.errors?.numbersList &&
                          formik?.errors?.numbersList[3] && (
                            <ErrorMsg text="اختر رقم" />
                          )}
                      </Box>
                    </>
                  ) : selectNumberType.id == 3 ? (
                    <>
                      <Box>
                        <CustomSelect
                          label="الرقم الاول"
                          placeholder=""
                          select={selectNumberOne}
                          handleSelect={handleSelectNumberOne}
                          selections={numberSelectionsArray}
                          colorLabel="black"
                        />
                        {formik?.errors?.numbersList &&
                          formik?.errors?.numbersList[0] && (
                            <ErrorMsg text="اختر رقم" />
                          )}
                      </Box>

                      <Box>
                        <CustomSelect
                          label="الرقم الثانى"
                          placeholder=""
                          select={selectNumberTwo}
                          handleSelect={handleSelectNumberTwo}
                          selections={numberSelectionsArray}
                          colorLabel="black"
                        />

                        {formik?.errors?.numbersList &&
                          formik?.errors?.numbersList[1] && (
                            <ErrorMsg text="اختر رقم" />
                          )}
                      </Box>
                      <Box>
                        <CustomSelect
                          label="الرقم الثالث"
                          placeholder=""
                          select={selectNumberThree}
                          handleSelect={handleSelectNumberThree}
                          selections={numberSelectionsArray}
                          colorLabel="black"
                        />
                        {formik?.errors?.numbersList &&
                          formik?.errors?.numbersList[2] && (
                            <ErrorMsg text="اختر رقم" />
                          )}
                      </Box>
                    </>
                  ) : selectNumberType.id == 2 ? (
                    <>
                      <Box>
                        <CustomSelect
                          label="الرقم الاول"
                          placeholder=""
                          select={selectNumberOne}
                          handleSelect={handleSelectNumberOne}
                          selections={numberSelectionsArray}
                          colorLabel="black"
                        />
                        {formik?.errors?.numbersList &&
                          formik?.errors?.numbersList[0] && (
                            <ErrorMsg text="اختر رقم" />
                          )}
                      </Box>

                      <Box>
                        <CustomSelect
                          label="الرقم الثانى"
                          placeholder=""
                          select={selectNumberTwo}
                          handleSelect={handleSelectNumberTwo}
                          selections={numberSelectionsArray}
                          colorLabel="black"
                        />

                        {formik?.errors?.numbersList &&
                          formik?.errors?.numbersList[1] && (
                            <ErrorMsg text="اختر رقم" />
                          )}
                      </Box>
                    </>
                  ) : (
                    <Box>
                      <CustomSelect
                        label="الرقم الاول"
                        placeholder=""
                        select={selectNumberOne}
                        handleSelect={handleSelectNumberOne}
                        selections={numberSelectionsArray}
                        colorLabel="black"
                      />
                      {formik?.errors?.numbersList &&
                        formik?.errors?.numbersList[0] && (
                          <ErrorMsg text="اختر رقم" />
                        )}
                    </Box>
                  )}

                  {/* <PinInput
                    bg="red"
                    defaultValue={defaultNumbers || ""}
                    value={formik?.values.numbersList
                      .toString()
                      .replace(/,/g, "")}
                  >
                    {formik.values.numbersList.length > 0 &&
                      formik.values.numbersList.map((number, index) => (
                        <Field
                          key={index}
                          name={`numbersList.${index}`}
                          component={CustomInputComponent}
                          boxShadow={number == "" && "0 0 0px 2px red"}
                        />
                      ))}
                  </PinInput> */}
                </Grid>
              </Box>
            </Flex>
          </Flex>
          <Box py="15px">
            <Text
              letterSpacing="19px"
              fontWeight="bold"
              textAlign="center"
              fontSize="22px">
              LU7AH
            </Text>
          </Box>
        </Box>
      </Flex>
    );
  }
);

export default LuhahInfo;
