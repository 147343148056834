import * as Yup from "yup";

export const paymentValidation = Yup.object().shape({
  name: Yup.string()
    .max(100, "يجب ان تدخل اقل من 100 احرف")
    .required("هذا الحقل مطلوب"),

  numberCart: Yup.string()
    .min(16, "يجب ادخال جميع الارقام")
    .required("هذا الحقل مطلوب"),

  month: Yup.string().required("هذا الحقل مطلوب"),

  year: Yup.string().required("هذا الحقل مطلوب"),

  cvv: Yup.string()

    .min(3, "يجب ادخال الارقام الثلاثة")
    .required("هذا الحقل مطلوب"),
});
