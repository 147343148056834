import { Box, Text, Flex, Link, Heading, Image } from "@chakra-ui/react";
import {
  Container,
  CustomInput,
  CustomButton,
  ErrorMsg,
} from "components/shared";
import { Formik, Form } from "formik";
import { signupValidation } from "validations/signup";
//import { Link } from "react-router-dom";
import { useMutation } from "react-query";
import { signup } from "apis/user";
import axios from "axios";
import googlePlay from "assets/images/google-play.png";
import appleStore from "assets/images/apple-store.png";
import phone from "assets/images/phone.png";
import lu7ahImg from "assets/images/lu7ah2.png";

const SignUp = () => {
  // const { mutate } = useMutation("sign-up", signup, {
  //   onSuccess: (res) => {
  //     console.log(res);
  //   },
  // });

  return (
    <Box my="50px">
      <Container>
        {/* <Text fontSize="19px" fontWeight="bold" color="#405fa1" mb="25px">
          تسجيل الدخول
        </Text>

        <Formik
          initialValues={{
            email: "",
            phone: "",
            userName: "",
            password: "",
          }}
          validationSchema={signupValidation}
          onSubmit={(values) => {
            console.log("yes");
            axios
              .post("customer-api/profile/update", {
                email: values.email,
                name: values.userName,
                mobile: values.phone,
              })
              .then((res) => {
                console.log(res);
              });
          }}
        >
          {(formik) => {
            console.log(formik);
            return (
              <Form>
                <Box mb="20px">
                  <CustomInput
                    type="email"
                    label="الايميل"
                    value={formik.values.email}
                    onChange={formik.handleChange("email")}
                    onBlur={formik.handleBlur("email")}
                  />
                  {formik.errors.email && formik.touched.email && (
                    <ErrorMsg text={formik.errors.email} />
                  )}
                </Box>

                <Box mb="20px">
                  <CustomInput
                    type="number"
                    label="رقم الجوال"
                    value={formik.values.phone}
                    onChange={formik.handleChange("phone")}
                    onBlur={formik.handleBlur("phone")}
                  />
                  {formik.errors.phone && formik.touched.phone && (
                    <ErrorMsg text={formik.errors.phone} />
                  )}
                </Box>

                <Box mb="20px">
                  <CustomInput
                    type="text"
                    label="اسم المستخدم"
                    value={formik.values.userName}
                    onChange={formik.handleChange("userName")}
                    onBlur={formik.handleBlur("userName")}
                  />
                  {formik.errors.userName && formik.touched.userName && (
                    <ErrorMsg text={formik.errors.userName} />
                  )}
                </Box>

                <Box mb="20px">
                  <CustomInput
                    type="password"
                    label=" كلمة المرور"
                    value={formik.values.password}
                    onChange={formik.handleChange("password")}
                    onBlur={formik.handleBlur("password")}
                  />
                  {formik.errors.password && formik.touched.password && (
                    <ErrorMsg text={formik.errors.password} />
                  )}
                </Box>

                <CustomButton name="تسجيل" mt="50px" mb="10px" type="submit" />
              </Form>
            );
          }}
        </Formik> */}
        <Flex
          alignItems="center"
          justifyContent="space-between"
          pos="relative"
          flexWrap={["wrap", "", "nowrap", "nowrap"]}
          flexDirection={["column-reverse", "", "row", ""]}>
          <Box w={["100%", "", "60%", ""]}>
            <Heading
              as="h2"
              fontWeight="bold"
              fontSize={["30px", "36px", "45px", ""]}
              mb="20px"
              textAlign={["center", "", "initial", ""]}>
              موقع لوحة صار في تطبيق!
            </Heading>

            <Text
              w={["90%", "80%", "85%", ""]}
              lineH="28px"
              textAlign={["center", "", "initial", ""]}
              m={["auto", "", "0", ""]}>
              لإكمال عملية التسجيل، حمل تطبيق إيرباق و أستفيد من كافة الخدمات
              اللي نقدمها من لوح سيارات، صيانة السيارات، و المساعدة على الطريق.
            </Text>

            <Flex
              mt="40px"
              justifyContent={["center", "", "initial", ""]}
              flexWrap={["wrap", "nowrap", "", ""]}>
              <Link
                me={["5px", "10px", "", ""]}
                mb={["10px", "0", "", ""]}
                href="https://play.google.com/store/apps/details?id=com.intcore.aerbag">
                <Image src={googlePlay} borderRadius="5px" />
              </Link>

              <Link href="https://apps.apple.com/us/app/aerbag/id1532251378">
                <Image src={appleStore} borderRadius="5px" />
              </Link>
            </Flex>
          </Box>
          <Box w={["100%", "", "40%", ""]} mb={["20px", "", "0", ""]}>
            <Image src={lu7ahImg} m={["auto", "", "0", ""]} />
          </Box>
        </Flex>
      </Container>
    </Box>
  );
};

export default SignUp;
