export const typeSelectionFilter = [
  { name: "الكل", id: "all" },
  { name: "خصوصى", id: "special" },
  { name: "نقل", id: "naql" },
];

export const typeSelection = [
  { name: "خصوصى", id: "special" },
  { name: "نقل", id: "naql" },
];

export const numberTypeSelections = [
  { name: "الكل", id: "" },
  { name: "فردى", id: 1 },
  { name: "ثنائى", id: 2 },
  { name: "ثلاثى", id: 3 },
  { name: "رباعى", id: 4 },
];

export const letters = [
  { name: "الكل", id: "all" },
  { name: "أ", id: "A" },
  { name: "ب", id: "b" },
  { name: "ح", id: "t" },
  { name: "د", id: "th" },
  { name: "ر", id: "g" },
  { name: "س", id: "g" },
  { name: "ص", id: "g" },
  { name: "ط", id: "g" },
  { name: "ع", id: "g" },
  { name: "ق", id: "g" },
  { name: "ك", id: "g" },
  { name: "ل", id: "g" },
  { name: "م", id: "g" },
  { name: "ن", id: "g" },
  { name: "ه", id: "g" },
  { name: "و", id: "g" },
  { name: "ى", id: "g" },
];

export const numbers = [
  { name: "اختر", id: "" },
  { name: "1", id: "1" },
  { name: "2", id: "2" },
  { name: "3", id: "3" },
  { name: "4", id: "4" },
  { name: "5", id: "5" },
  { name: "6", id: "6" },
  { name: "7", id: "7" },
  { name: "8", id: "8" },
  { name: "9", id: "9" },
];

export const cities = [
  { name: "الدمام", id: "Dammam" },
  { name: "الرياض", id: "Riyadh" },
  { name: "جدة", id: "Jeddah" },
  { name: "الخبر", id: "Khaybar" },
  { name: "الجبيل", id: "Jubail" },
  { name: "القطيف", id: "Qatif" },
  { name: "الخفجى", id: "Khafji" },
  { name: "مكة", id: "Mecca" },
  { name: "المدينة", id: "Medina" },
  { name: "الطائف", id: "Taif" },
  { name: "تبوك", id: "Tabuk" },
  { name: "الخرج", id: "Al-Kharj" },
  { name: "بريدة", id: "Buraydah" },
  { name: "عنيزة", id: "Unayzah" },
  { name: "خميس مشيط", id: "Khamis Mushait" },
  { name: "الهفوف", id: "Hofuf" },
  { name: "المبرز", id: "Al Mubarraz" },
  { name: "حفر الباطن", id: "Hafar Al Batin" },
  { name: "حائل", id: "Hail" },
  { name: "نجران", id: "Najran" },
  { name: "أبها", id: "Abha" },
  { name: "ينبع", id: "Yanbu" },
  { name: "عرعر", id: "Arar" },
  { name: "سكاكا", id: "Sakaka" },
  { name: "جازان", id: "Jazan" },
];

export const sortingSelections = [
  {
    name: "التاريخ من الاحدث الى الاقدم",
    id: "latest",
  },
  {
    name: "التاريخ من الاقدم الى الاحدث",
    id: "oldest",
  },
  // {
  //   name: "السعر: من الاقل الى الاعلى",
  //   id: "lower price",
  // },
  // {
  //   name: "السعر: من الاعلى الى الاقل",
  //   id: "heigh price",
  // },
];

export const costType = [
  { name: "السعر بالريال السعودى", id: "ryall" },
  { name: "السعر على السوم", id: "soom" },
];

export const transportCost = [
  { name: " على البائع ", id: "on_seller" },
  { name: "على المشترى", id: "on_customer" },
];

export const months = [
  { name: "1", id: "1" },
  { name: "2", id: "2" },
  { name: "3", id: "3" },
  { name: "4", id: "4" },
  { name: "5", id: "5" },
  { name: "6", id: "6" },
  { name: "7", id: "7" },
  { name: "8", id: "8" },
  { name: "9", id: "9" },
  { name: "10", id: "10" },
  { name: "11", id: "11" },
  { name: "12", id: "12" },
];

export const years = [];

for (let i = 0; i < 15; i++) {
  years.push({
    name: new Date().getFullYear() + i,
    id: `${new Date().getFullYear() + i}`,
  });
}
