import { useState, memo } from "react";
import { Box, Flex, Text, Spinner, useToast } from "@chakra-ui/react";

const Modal = ({ value, price }) => {
  const CustomText = ({ textValue, children, ...props }) => {
    return (
      <Text fontWeight="bodl" {...props}>
        {textValue}
        {children}
      </Text>
    );
  };

  return (
    <>
      <CustomText
        borderBottom="1px solid rgb(212 ,218 ,222)"
        pb="5px"
        display="inline-block"
        mb="10px">
        {parseFloat(price) < 1000 ? (
          <Text fontWeight="bold" textAlign="center">
            رسوم الموقع مع ضريبة القيمة المضافة هي : العمولة علينا
          </Text>
        ) : (
          <Text>
            رسوم الموقع مع ضريبة القيمة المضافة هي :{" "}
            <Text as="span" fontWeight="bold" fontSize="20px">
              {value}
            </Text>{" "}
            ريال
          </Text>
        )}
      </CustomText>
      <CustomText textValue="شركة فلاة الحلول للخدمات التجارية" />
      <CustomText textValue="سجل تجاري رقم: 2051230463" />
      <CustomText textValue="الرقم الضريبي: 310787215100003" />
      <CustomText textValue="  العليا، الخبر، المملكة العربية السعودية" />
    </>
  );
};

export default Modal;
