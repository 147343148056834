import { Button, Box } from "@chakra-ui/react";

const CustomButton = ({ name, icon, loading, ...props }) => {
  return (
    <Button
      //h="38px"
      minH="38px"
      maxH="100%"
      fontWeight="normal"
      bg="bg.darkBlue"
      color="white"
      border="none"
      borderRadius="5px"
      w="100%"
      _hover={{}}
      _focus={{ outline: "none" }}
      _active={{}}
      display={icon ? "flex" : "initial"}
      alignItems="center"
      {...props}
    >
      {loading ? loading : null}
      {name}
      {icon ? <Box as={icon} ms="5px" fontSize="16px" /> : null}
    </Button>
  );
};

export default CustomButton;
