import {
  Table as T,
  Thead,
  Tbody,
  Tr,
  Th,
  TableContainer,
} from "@chakra-ui/react";

const Table = ({
  theadHeight,
  theadBg,
  headers,
  children,
  ThWidths,
  tableHeadPadding = "25px",
  overflowContainer = "auto",
  ...props
}) => {
  return (
    <TableContainer overflowX={overflowContainer} overflowY="visible">
      <T variant="simple" {...props}>
        <Thead h={theadHeight} bg="#4e4848">
          <Tr bg={theadBg}>
            {headers.map((header, i) => {
              return (
                <Th
                  key={i}
                  textTransform="capitalize"
                  fontSize="16px"
                  w={ThWidths ? ThWidths[i] : "initial"}
                  padding={tableHeadPadding}
                  color="white"
                >
                  {header}
                </Th>
              );
            })}
          </Tr>
        </Thead>
        <Tbody>{children}</Tbody>
      </T>
    </TableContainer>
  );
};

export default Table;
