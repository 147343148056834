import * as Yup from "yup";

export const contactValidation = Yup.object().shape({
  name: Yup.string()
    .min(3, "يجب ان تدخل على الاقل ثلاثة احرف")
    .max(100, "يجب ان تدخل اقل من 100 احرف")
    .required("هذا الحقل مطلوب"),

  phone: Yup.string().required("هذا الحقل مطلوب"),

  email: Yup.string()
    .email("ايميل غير صالح")
    .max(250, "يجب ان تدخل اقل من 100 احرف"),

  verifiyCode: Yup.string().required("هذا الحقل مطلوب"),
});
