import { useState } from "react";
import {
  Box,
  Tr,
  Td,
  Text,
  Flex,
  Grid,
  useToast,
  Switch,
  Link,
} from "@chakra-ui/react";
import {
  Table,
  CustomModal,
  Loading,
  CustomToast,
  CustomButton,
  Luhah,
  FlexContent,
} from "components/shared";
import { myListings, updateList } from "apis/lu7ah";
import ViewPopover from "./view-popover";
import { useQuery, useMutation } from "react-query";
import PopupDelete from "./popup-delete";
import AddPriceModal from "./add-priceModal";
import moment from "moment";

const Card = ({
  id,
  serial,
  date,
  chars_ar,
  chars_en,
  numbers,
  is_hidden,
  is_sold,
  type,
  invoice_url,
  receipt_url,
  commission_paid_at,
  refetch,
}) => {
  const toast = useToast();
  const [showAddPriceModal, setShowAddPriceModal] = useState(false);
  const [showDeleteLu7ahModal, setShowDeleteLu7ahModal] = useState(false);

  const { mutate } = useMutation("update-list", updateList, {
    onSuccess: () => {
      toast({
        render: (props) => {
          return (
            <CustomToast
              title="حالة اللوحة "
              description="تم تعديل حالة اللوحة بنجاح"
              status="success"
              onClose={props.onClose}
            />
          );
        },
      });
      refetch();
    },
  });

  const handleShowAds = (is_hidden) => {
    if (is_hidden == 0) {
      mutate({
        id: id,
        data: {
          is_hidden: 1,
        },
      });
    } else {
      mutate({
        id: id,
        data: {
          is_hidden: 0,
        },
      });
    }
  };

  return (
    <>
      <Box border="1px solid #e7e3e3" borderRadius="10px">
        <Box p="15px">
          <Flex justifyContent="space-between" mb="20px">
            <Flex>
              {is_sold ? (
                <CustomButton
                  as={Link}
                  href={receipt_url}
                  target="_blank"
                  name="عرض الفاتورة"
                  display="flex"
                  mt="10px"
                  _hover={{ textDecoration: "none" }}
                />
              ) : (
                <>
                  <Switch
                    me="5px"
                    size="lg"
                    transition="0.3s"
                    dir="rtl"
                    isChecked={!is_hidden && !is_sold == 1}
                    disabled={is_sold == 1}
                    onChange={() => {
                      handleShowAds(is_hidden);
                    }}
                  />
                  {is_hidden == 0 && !is_sold == 1
                    ? "    الإعلان ظاهر"
                    : "   الإعلان مخفى"}
                </>
              )}
            </Flex>

            <ViewPopover
              is_sold={is_sold}
              id={id}
              setShowDelete={setShowDeleteLu7ahModal}
            />
          </Flex>
          <Luhah
            charsAr={chars_ar}
            charsEn={chars_en}
            numbers={numbers}
            type={type}
          />
          <Box mt="15px">
            <FlexContent label="رقم الاعلان" value={serial} />
            <FlexContent label="التاريخ" value={date} />
          </Box>
        </Box>

        {!is_sold ? (
          <Box p="15px" pt="0">
            <CustomButton
              name="دفع العمولة"
              display="flex"
              mt="10px"
              onClick={() => setShowAddPriceModal(true)}
            />
          </Box>
        ) : (
          <Text
            textAlign="center"
            py="10px"
            bg="#f3f3f3"
            borderTop="1px solid #e7e3e3">
            تم دفع العمولة فى {moment(commission_paid_at).format("yyyy-M-D")}
          </Text>
        )}
      </Box>

      <CustomModal
        show={showAddPriceModal}
        setShow={setShowAddPriceModal}
        title="حساب العمولة">
        <AddPriceModal id={id} />
      </CustomModal>

      <CustomModal
        titleMarginBottom="20px"
        show={showDeleteLu7ahModal}
        setShow={setShowDeleteLu7ahModal}
        title="حذف اللوحة">
        <PopupDelete
          id={id}
          refetch={refetch}
          closePopup={setShowDeleteLu7ahModal}
        />
      </CustomModal>
    </>
  );
};

export default Card;
