import { Box, Text } from "@chakra-ui/react";

const TextCard = ({ text, value, textIsHidden }) => {
  return (
    <Box border="1px solid #c6c6c6" boxShadow="0 0 19px 0px #ccc" p="15px">
      <Text textAlign="center" mb="25px">
        {text}
      </Text>

      <Text
        fontWeight="bold"
        color="#137fce"
        fontSize="19px"
        textAlign="center"
      >
        {textIsHidden != 1 ? value : "التواصل من خلال التطبيق"}
      </Text>
    </Box>
  );
};

export default TextCard;
