import axios from "axios";

export const signup = (data) => {
  return axios.post("customer-api/profile/update", { data });
};

export const signin = (data) => {
  return axios.post("auth/firebase", data);
};

export const getUserData = (data) => {
  return axios.get("customer-api/profile");
};

export const contact = (data) => {
  return axios.post("api/contact", data);
};
