import axios from "axios";

export const getMyFavourites = () => {
  return axios.get("lu7ah-api/my-favourite-listings");
};

export const addFavourite = (id) => {
  return axios.post("lu7ah-api/addFavourite", {
    listing_id: id,
  });
};

export const removeFavourite = (id) => {
  return axios.post("lu7ah-api/removeFavourite", {
    listing_id: id,
  });
};
