import { Box, Flex, Spinner, Text, useToast } from "@chakra-ui/react";
import { CustomButton, CustomToast } from "components/shared";
import { useMutation } from "react-query";
import { deleteList } from "apis/lu7ah";

const PopupDelete = ({ id, refetch, closePopup }) => {
  const toast = useToast();

  const { mutate, isLoading } = useMutation(deleteList, {
    onSuccess: () => {
      toast({
        render: (props) => {
          return (
            <CustomToast
              title="حذف اللوحة "
              description="تم حذف اللوحة بنجاح"
              status="success"
              onClose={props.onClose}
            />
          );
        },
      });
      refetch();
    },
    onError: () => {
      toast({
        render: (props) => {
          return (
            <CustomToast
              title="حذف اللوحة "
              description="حدث خطأ ما برجاء اعادة المحاولة"
              status="error"
              onClose={props.onClose}
            />
          );
        },
      });
    },
  });

  return (
    <Flex justifyContent="center">
      <Box maxW="80%">
        <Text textAlign="center" mb="30px">
          هل انت متأكد من حذف اللوحة , سيتم حذفها بشكل نهائى
        </Text>

        <Flex>
          <CustomButton
            disabled={isLoading}
            name="حذف"
            me="10px"
            display="flex"
            alignItems="center"
            loading={isLoading && <Spinner me="5px" />}
            onClick={() => {
              mutate(id);
            }}
          />
          <CustomButton
            disabled={isLoading}
            name="الغاء"
            onClick={() => {
              closePopup(false);
            }}
          />
        </Flex>
      </Box>
    </Flex>
  );
};

export default PopupDelete;
