import { Box, Flex } from "@chakra-ui/react";
import Header from "./header";
import Footer from "./footer";
import DownloadApp from "components/download-app";

const Layout = ({ children }) => {
  return (
    <Box>
      <Header />

      <Box minH="calc(100vh - 245px)">{children}</Box>
      <DownloadApp />
      <Footer />
    </Box>
  );
};

export default Layout;
