import axios from "axios";
export const payment = ({
  id,
  commission,
  after_completion_action,
  use_v2,
}) => {
  return axios.post(`lu7ah-api/listings/${id}/commission`, {
    commission,
    after_completion_action,
    use_v2,
  });
};
