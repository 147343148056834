import axios from "axios";

export const configureAxios = (accessToken = null, language = null) => {
  axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

  axios.defaults.headers.common["Accept-Language"] = "ar";
  axios.defaults.headers.common["X-localization"] = "ar";
  axios.defaults.headers.common["X-platform"] = "web";

  if (accessToken)
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${accessToken.replace(/^"(.*)"$/, "$1")}`;
  axios.defaults.headers.post["Content-Type"] = "application/json";
};
