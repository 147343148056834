import { useState, useEffect, useContext } from "react";
import { Box, Spinner, Text, useToast } from "@chakra-ui/react";
import {
  Container,
  CustomInput,
  CustomButton,
  ErrorMsg,
  CustomToast,
} from "components/shared";
import { userContext } from "contexts/user";
import { Formik, Form } from "formik";
import { OtpValidation } from "validations/confirm-otp";

import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import { signin, getUserData } from "apis/user";

const ConfirmOtp = ({ userPhone }) => {
  const [isLoadingConfirmOtp, setIsloadingConfirmOtp] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();

  const { setToken } = useContext(userContext);

  const { refetch, isLoading: loadingData } = useQuery(
    "get-user-data",
    getUserData,
    {
      enabled: false,
      onSuccess: (res) => {
        localStorage.setItem("lu7ah_user", JSON.stringify(res?.data?.user));
        navigate("/");
      },
    }
  );

  const { mutate, isLoading } = useMutation(
    "signin-with-aerbagBackend",
    signin,
    {
      onSuccess: (res) => {
        if (!res?.data?.user?.is_profile_ready) {
          toast({
            render: (props) => {
              return (
                <CustomToast
                  title={"لا يمكن الدخول"}
                  description="حسابك غير مكتمل برجاء اكمال البيانات، استخدم تطبيق لوحة لاكمال البيانات"
                  status="error"
                  onClose={props.onClose}
                />
              );
            },
          });
        } else {
          localStorage.setItem("lu7ah_token", res?.data?.token);
          setToken(JSON.stringify(res?.data?.token));
          refetch();
        }

      },
      onError: () => {
        toast({
          render: (props) => {
            return (
              <CustomToast
                title={"خطأ"}
                description="حدث خطأ ما برجاء اعادة المحاولة"
                status="success"
                onClose={props.onClose}
              />
            );
          },
        });
      },
    }
  );

  const verifyOtp = (otp) => {
    let confirmationResult = window.confirmationResult;
    setIsloadingConfirmOtp(true);
    confirmationResult.confirm(otp).then((result) => {
      setIsloadingConfirmOtp(false);
      // User signed in successfully.
      const user = result.user;
      mutate({
        firebase_token: user?.accessToken,
        type: "customer",
        device_token: "string",
        platform: "web",
      });
    });
  };

  return (
    <Box>
      <Container>
        <Text
          fontSize="19px"
          fontWeight="bold"
          color="#405fa1"
          mb="25px"
          textAlign="center"
        >
          رقم التحقق
        </Text>

        <Formik
          initialValues={{
            otp: "",
          }}
          validationSchema={OtpValidation}
          onSubmit={(values) => {
            verifyOtp(values.otp);
          }}
        >
          {(formik) => {
            return (
              <Form>
                <Box w={["95%", "85%", "60%", ""]} m="auto">
                  <CustomInput
                    label="رقم التحقق"
                    mb="10px"
                    value={formik.values.otp}
                    onChange={formik.handleChange("otp")}
                    onBlur={formik.handleBlur("otp")}
                  />
                  {/* <Text fontSize="12px">مثال : 05xxxxxxxx</Text> */}
                  {formik.errors.otp && formik.touched.otp && (
                    <ErrorMsg text={formik.errors.otp} />
                  )}
                  <CustomButton
                    name="ارسال"
                    mt="50px"
                    mb="10px"
                    type="submit"
                    display="flex"
                    alignItems="center"
                    loading={
                      (isLoading || loadingData || isLoadingConfirmOtp) && (
                        <Spinner me="5px" />
                      )
                    }
                    disabled={isLoading || loadingData || isLoadingConfirmOtp}
                  />
                  {/* <Text textAlign="center">
                    {" "}
                    ليس لديك حساب؟
                    <Box
                      fontWeight="bold"
                      color="#007bff"
                     
                    >
                      اضغط هنا لاعادة الارسال
                    </Box>
                  </Text> */}
                </Box>
                {/* <Box id="request-recaptcha"></Box> */}
              </Form>
            );
          }}
        </Formik>
      </Container>
    </Box>
  );
};

export default ConfirmOtp;
