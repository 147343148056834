import { useState } from "react";
import { Box, Text } from "@chakra-ui/react";
import { Container, CustomButton, CustomModal } from "components/shared";
import { AddPriceModal } from "components/user";
import { Link } from "react-router-dom";

const Faq = ({ q, answer, ...props }) => {
  return (
    <Box {...props}>
      <Text color="text.darkBlue" mb="10px">
        {q}
      </Text>
      <Text color="#919191">- {answer}</Text>
    </Box>
  );
};
const About = () => {
  const [showModal, setShowModal] = useState(false);

  return (
    <Box py="30px">
      <Container>
        <Box>
          <Box>
            <Text
              fontWeight="bold"
              fontSize="30px"
              color="text.darkBlue"
              mb="20px">
              عن منصة لوحة
            </Text>
            <Box w={["100%", "100%", "80%", "80%"]}>
              <Text color="#919191">
                منصة لوحة هي خدمة تقنية تُمكن المستخدم من تأليف و نشر إعلانات
                لوح السيارات. تتيح المنصة إمكانية البحث و فرز الإعلانات المنشورة
                من المستخدمين و التواصل بينهم.
              </Text>
              <Text color="#919191" my="10px">
                تقدم المنصة الخدمة وفق ضوابط و شروط ثابتة تم الاتفاق عليها مع
                المستخدم خلال معاهدة إستخدام المنصة الموجودة أسفل الصفحة.
              </Text>
              <Text color="#919191">
                منصة لوحة هي جهة تجارية سعودية 100% مسجلة بأسم شركة فلاة الحلول
                للخدمات التجارية.
              </Text>
            </Box>
          </Box>

          <Box my="40px">
            <Text
              fontWeight="bold"
              fontSize="25px"
              color="text.darkBlue"
              mb="20px">
              عنوان المنصة{" "}
            </Text>
            <Box>
              <Text color="#919191">شركة فلاة الحلول للخدمات التجارية</Text>
              <Text color="#919191" my="5px">
                رمز بريدي: 34448 - العليا الخبر
              </Text>
              <Text color="#919191">المملكة العربية السعودية</Text>
            </Box>
          </Box>

          <Box>
            <Text
              fontWeight="bold"
              fontSize="20px"
              color="text.darkBlue"
              mb="20px">
              اسئلة شائعة{" "}
            </Text>
            <Box>
              <Faq
                q="هل تقوم منصة لوحة بالإعلان أو بيع لوح السيارات؟"
                answer="لا تقوم منصة لوحة بالإعلان أو بيع لوح السيارت. تقدم فقط خدمة تُمكن المستخدم من الإعلان في المنصة"
                mb="20px"
              />
              <Faq
                q="هل تقبل منصة لوحة الوسطاء في عرض اللوح؟"
                answer="لا تقبل منصة لوحة الوسطاء في الإعلانات لحفظ حقوق ملاك اللوح. في حال تم العرض من قبل الوسيط، يتحمل الوسيط جميع التبعات القانونية وسيتم مشاركة معلومات الوسيط مع الجهات الحكومية في حال لزم الأمر"
                mb="20px"
              />
              <Faq
                q="كيفية الإبلاغ عن لوحة أو إعلان إحتيالي؟"
                answer="في صفحة كل إعلان في الموقع أو التطبيق، يوجد زر 'إبلاغ عن الإعلان' كل اللي عليك تبلغ عن اللوحة وفريق الدعم الفني في منصة لوحة بيتخذ الإجراء اللازم مع المعلن."
                mb="20px"
              />
              <Faq
                q="كيفية التواصل مع إدارة منصة لوحة؟"
                answer='تستطيع التواصل مع الإدارة عن طريق تعبئة الفورم الموجود في صفحة "تواصل معنا" أو عن طريق الخيارات الموجودة في الأسفل'
              />
            </Box>
          </Box>
        </Box>

        {/* <CustomButton
          name="حساب العمولة"
          mt="10px"
          onClick={() => setShowModal(true)}
          // maxW="120px"
          // display="flex"
          // justifyContent="center"
        /> */}

        <CustomModal
          show={showModal}
          setShow={setShowModal}
          title="حساب العمولة">
          <AddPriceModal isShow />
        </CustomModal>
      </Container>
    </Box>
  );
};

export default About;
