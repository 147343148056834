import { Flex, Text } from "@chakra-ui/react";

const FlexContent = ({ label, value, ...props }) => {
  return (
    <Flex alignItems="center" justifyContent="space-between" {...props}>
      <Text fontWeight="bold">{label}</Text>
      <Text>{value} </Text>
    </Flex>
  );
};

export default FlexContent;
