import { useState } from "react";
import {
  Box,
  Flex,
  Text,
  PinInput,
  PinInputField,
  Grid,
  Input,
  Tooltip,
  Button,
  Image,
} from "@chakra-ui/react";
import { CustomInput, CustomSelect, CustomButton } from "components/shared";
import { SlQuestion } from "react-icons/sl";
import { BsCreditCard2Front } from "react-icons/bs";
import { months, years } from "components/shared/selections";

const PayModal = ({ price }) => {
  const [month, setMonth] = useState(null);
  const [year, setYear] = useState(null);

  const handleSelectYear = (e) => {
    setYear(e);
  };
  const handleSelectMonth = (e) => {
    setMonth(e);
  };

  const PaymentMethod = ({ icon, name }) => {
    return (
      <Flex
        bg="bg.darkBlue"
        py="20px"
        alignItems="center"
        justifyContent="center"
        borderRadius="5px"
      >
        <Text color="white">{name}</Text>
        <Box as={icon} color="white" ms="5px" fontSize="30px" />
      </Flex>
    );
  };

  return (
    <Box dir="ltr">
      <Box mb="40px">
        <Text
          fontWeight="bold"
          pos="relative"
          mb="40px"
          _after={{
            content: `""`,
            pos: "absolute",
            bottom: "-8px",
            w: "20px",
            h: "1px",
            bg: "#ced4da",
            right: "0",
          }}
        >
          طريقةالدفع
        </Text>
        <Grid gridTemplateColumns="repeat(3,1fr)" gap="10px">
          <PaymentMethod name="Credit Card" icon={BsCreditCard2Front} />
          <PaymentMethod name="Credit Card" icon={BsCreditCard2Front} />
        </Grid>
      </Box>

      <CustomInput label="الاسم على الكارت" mb="30px" />

      <Box>
        <Text mb="5px">رقم الكارت</Text>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          border="1px solid #ced4da"
          borderRadius="5px"
          px="15px"
          py="4px"
        >
          <Grid gap="0" gridTemplateColumns="repeat(19,15px)">
            <PinInput placeholder="0">
              <PinInputField
                type="number"
                border="none"
                w="15px"
                _focusVisible={{ border: "none" }}
              />
              <PinInputField
                type="number"
                border="none"
                w="15px"
                _focusVisible={{ border: "none" }}
              />
              <PinInputField
                type="number"
                border="none"
                w="15px"
                _focusVisible={{ border: "none" }}
              />
              <PinInputField
                type="number"
                border="none"
                w="15px"
                _focusVisible={{ border: "none" }}
              />

              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                mx="5px"
                border="none"
                w="initial"
              >
                -
              </Box>

              <PinInputField
                type="number"
                border="none"
                w="15px"
                _focusVisible={{ border: "none" }}
              />
              <PinInputField
                type="number"
                border="none"
                w="15px"
                _focusVisible={{ border: "none" }}
              />
              <PinInputField
                type="number"
                border="none"
                w="15px"
                _focusVisible={{ border: "none" }}
              />
              <PinInputField
                type="number"
                border="none"
                w="15px"
                _focusVisible={{ border: "none" }}
              />

              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                mx="5px"
                border="none"
                w="initial"
              >
                -
              </Box>

              <PinInputField
                type="number"
                border="none"
                w="15px"
                _focusVisible={{ border: "none" }}
              />
              <PinInputField
                type="number"
                border="none"
                w="15px"
                _focusVisible={{ border: "none" }}
              />
              <PinInputField
                type="number"
                border="none"
                w="15px"
                _focusVisible={{ border: "none" }}
              />
              <PinInputField
                type="number"
                border="none"
                w="15px"
                _focusVisible={{ border: "none" }}
              />

              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                mx="5px"
                border="none"
                w="initial"
              >
                -
              </Box>

              <PinInputField
                type="number"
                border="none"
                w="15px"
                _focusVisible={{ border: "none" }}
              />
              <PinInputField
                type="number"
                border="none"
                w="15px"
                _focusVisible={{ border: "none" }}
              />
              <PinInputField
                type="number"
                border="none"
                w="15px"
                _focusVisible={{ border: "none" }}
              />
              <PinInputField
                type="number"
                border="none"
                w="15px"
                _focusVisible={{ border: "none" }}
              />
            </PinInput>
          </Grid>
        </Flex>
      </Box>

      <Grid gridTemplateColumns="repeat(3,1fr)" gap="10px" my="30px">
        <CustomSelect
          label="الشهر"
          colorLabel="black"
          placeholder="اختر شهر"
          py="9px"
          selections={months}
          select={month}
          handleSelect={handleSelectMonth}
          maxMinueH="200px"
        />

        <CustomSelect
          label="السنه"
          colorLabel="black"
          placeholder="اختر سنة"
          py="9px"
          selections={years}
          select={year}
          handleSelect={handleSelectYear}
          maxMinueH="200px"
        />

        <Box>
          <Text mb="5px">CVV</Text>
          <Flex
            border="1px solid #ced4da"
            borderRadius="5px"
            alignItems="center"
            pos="relative"
          >
            <Input
              border="0"
              maxLength="3"
              //type="number"
              h="100%"
              _focusVisible={{ boxShadow: "none" }}
              py="10px"
            />

            <Tooltip
              hasArrow
              label="Enter cvv number back to your card"
              bg="red.600"
            >
              <Button
                minW="4px"
                p="0"
                w="27px"
                h="21px"
                display="grid"
                borderRadius="0"
                me="5px"
                bg="transparent"
                _hover={{ bg: "none" }}
              >
                <Box as={SlQuestion} cursor="pointer" />
              </Button>
            </Tooltip>
          </Flex>
        </Box>
      </Grid>

      <Grid
        placeItems="center"
        borderRadius="5px"
        p="20px"
        bg="#f8f9fa"
        mb="30px"
      >
        <Text>القيمة الكلية : {price}</Text>
      </Grid>
      <CustomButton name="ادفع الان" h="45px" />
    </Box>
  );
};

export default PayModal;
