import { useEffect } from "react";
import { Box, Text, Flex, useToast } from "@chakra-ui/react";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { AiOutlineClose } from "react-icons/ai";
import { RiErrorWarningFill } from "react-icons/ri";

const CustomToast = ({ title, description, onClose, status }) => {
  return (
    <Box
      bg={
        status == "success"
          ? "#008d00"
          : status == "warning"
          ? "#fdbd72"
          : "#d11717"
      }
      p="10px"
      borderRadius="10px"
      minW="200px"
      pos="relative"
    >
      <Flex justifyContent="space-between">
        <Flex>
          <Box
            as={
              status == "success" || status == "warning"
                ? BsFillCheckCircleFill
                : RiErrorWarningFill
            }
            color="white"
            me="5px"
          />
          <Box mt="-5px">
            <Text color="white" fontWeight="bold">
              {title}
            </Text>
            <Text color="white">{description}</Text>
          </Box>
        </Flex>
        <Box
          onClick={onClose}
          as={AiOutlineClose}
          color="white"
          cursor="pointer"
        />
      </Flex>
    </Box>
  );
};

export default CustomToast;
