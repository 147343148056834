import {
  Box,
  Grid,
  Popover,
  PopoverTrigger,
  Button,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
} from "@chakra-ui/react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Link } from "react-router-dom";

const Item = ({ name, ...props }) => {
  return (
    <Box
      // onClick={() => setShowEdit(true)}
      cursor="pointer"
      textAlign="center"
      textTransform="capitalize"
      transition=".3s"
      _hover={{ bg: "#efefef" }}
      {...props}>
      {name}
    </Box>
  );
};

const ViewPopover = ({
  setShowDelete,
  setShowEdit,
  isDeleteExist,
  dotsColor,
  children,
  is_sold,
  id,
  ...props
}) => {
  return (
    <Grid placeItems="center" pos="relative">
      <Popover placement="bottom">
        <PopoverTrigger>
          <Button
            color="text.1100"
            fontSize="18px"
            h="18px"
            justifyContent="flex-end"
            bg="transparent"
            p="0"
            minW="5px"
            _focus={{}}
            _hover={{}}
            _active={{}}
            {...props}>
            <Box as={BsThreeDotsVertical} color={dotsColor} />
          </Button>
        </PopoverTrigger>
        <PopoverContent w="120px" _focus={{}}>
          <PopoverArrow />
          <PopoverBody p="0">
            <Item
              name="عرض اللوحة"
              as={Link}
              to={`/edit/${id}`}
              my="5px"
              display="block"
            />
            {!is_sold ? (
              <>
                <Item
                  name="تعديل اللوحة"
                  as={Link}
                  to={`/edit/${id}`}
                  my="5px"
                  display="block"
                />
                <Item
                  name="حذف اللوحة"
                  mb="5px"
                  onClick={() => setShowDelete(true)}
                />
              </>
            ) : null}
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Grid>
  );
};

export default ViewPopover;
