import { useEffect, useState } from "react";
import { Box, Link, Image, Text, Flex } from "@chakra-ui/react";
import { browserName, isMobile, deviceType } from "react-device-detect";
import aerbagLogo from "assets/images/small-logo.svg";
import operaImg from "assets/images/opera.png";
import chromeImg from "assets/images/chrome.png";
import firefoxImg from "assets/images/firefox.png";
import edgeImg from "assets/images/microsoft.png";
import safariImg from "assets/images/safari.png";

const DownloadApp = () => {
  const [deviceType, setDeviceType] = useState(null);

  const [showAlert, setShowAlert] = useState(false);

  const getMobileOperatingSystem = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      setDeviceType("Windows");
    } else if (/android/i.test(userAgent)) {
      setDeviceType("Android");
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      setDeviceType("Ios");
    } else {
      setDeviceType("unknown");
    }
  };

  useEffect(() => {
    getMobileOperatingSystem();
    setTimeout(() => {
      if (deviceType != "unknown" && isMobile) {
        setShowAlert(true);
      }
    }, 3000);
  }, []);

  useEffect(() => {
    if (showAlert) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
    }
  }, [showAlert]);

  const IconText = ({ name, buttonActionName, linkUrl, children }) => {
    return (
      <Flex
        justifyContent="space-between"
        mb="25px"
        flexDirection="row-reverse"
      >
        {linkUrl ? (
          <Link
            href={linkUrl}
            bg="bg.darkBlue"
            display="flex"
            alignItems="center"
            justifyContent="center"
            p="10px"
            borderRadius="35px"
            color="white"
            px="15px"
            fontWeight="bold"
            transition=".3s"
            minW="120px"
            _hover={{
              textDecor: "none",
              bg: "#188ee5",
            }}
          >
            {buttonActionName}
          </Link>
        ) : (
          <Box
            bg="bg.darkBlue"
            display="flex"
            alignItems="center"
            justifyContent="center"
            p="10px"
            borderRadius="35px"
            color="white"
            px="15px"
            fontWeight="bold"
            transition=".3s"
            minW="120px"
            cursor="pointer"
            _hover={{
              bg: "#188ee5",
            }}
            onClick={() => {
              setShowAlert(false);
            }}
          >
            {buttonActionName}
          </Box>
        )}

        <Flex alignItems="center">
          {children}
          <Text fontWeight="bold" ms="10px">
            {name}
          </Text>
        </Flex>
      </Flex>
    );
  };

  if (deviceType == "unknown" && !isMobile) {
    return;
  }

  return (
    <>
      <Box
        bg="white"
        p="20px"
        pos="fixed"
        transition="0.5s"
        bottom={showAlert == true ? "0" : "-100%"}
        bg="white"
        w="100%"
        zIndex="222"
        dir="rtl"
      >
        <Text textAlign="center" fontWeight="bold" mb="20px">
          لتصفح أسهل، حمل التطبيق:
        </Text>

        <IconText
          name="إيرباق | AerBag "
          buttonActionName="تنزيل"
          linkUrl={
            deviceType == "Android"
              ? "https://play.google.com/store/apps/details?id=com.intcore.aerbag"
              : deviceType == "Ios"
              ? "https://apps.apple.com/us/app/aerbag/id1532251378"
              : null
          }
        >
          <Image src={aerbagLogo} w="50px" />
        </IconText>

        <IconText name="المتابعة على المتصفح" buttonActionName="متابعة">
          <Image
            src={
              browserName == "Chrome"
                ? chromeImg
                : browserName == "Opera"
                ? operaImg
                : browserName == "Safari"
                ? safariImg
                : browserName == "Edge"
                ? edgeImg
                : browserName == "Firefox"
                ? firefoxImg
                : null
            }
            w="45px"
          />
        </IconText>
      </Box>
      {showAlert ? (
        <Box
          pos="fixed"
          top="0"
          left="0"
          w="100%"
          h="100%"
          bg="rgba(0,0,0,0.2)"
          zIndex="111"
        />
      ) : null}
    </>
  );
};

export default DownloadApp;
