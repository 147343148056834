import { Box } from "@chakra-ui/react";

const Container = ({ children, ...props }) => {
  return (
    <Box maxW="1140px" m="auto" px={["15px", "15px", "15px", "0"]} {...props}>
      {children}
    </Box>
  );
};

export default Container;
