import { useState, useRef } from "react";
import { Box, Text, Image, Flex } from "@chakra-ui/react";

const BankAccount = ({ accountNumber, iban, imgSrc }) => {
  const accountNumberRef = useRef();
  const ibanNumberRef = useRef();

  return (
    <Box bg="#f6f6f6" pos="relative" borderRadius="10px" p="20px" py="40px">
      <Box mb="15px">
        <Text>رقم الحساب</Text>
        <Flex alignItems="center" flexWrap={["wrap", "noWrap", "nowrap", ""]}>
          <Text
            fontSize="18px"
            fontWeight="bold"
            color="#1073c0"
            ref={accountNumberRef}
            me="5px">
            {accountNumber}
          </Text>
          <Text
            bg="#1073c0"
            p="5px"
            borderRadius="5px"
            color="white"
            cursor="pointer"
            w={["100%", "initial", "", ""]}
            textAlign="center"
            onClick={(e) => {
              navigator.clipboard.writeText(accountNumberRef.current.innerText);
            }}>
            نسخ
          </Text>
        </Flex>
      </Box>

      <Box>
        <Text>الايبان</Text>
        <Flex alignItems="center" flexWrap={["wrap", "noWrap", "nowrap", ""]}>
          <Text
            fontSize="18px"
            fontWeight="bold"
            color="gray"
            ref={ibanNumberRef}
            me="5px">
            {iban}
          </Text>
          <Text
            bg="#1073c0"
            p="5px"
            borderRadius="5px"
            color="white"
            cursor="pointer"
            w={["100%", "initial", "", ""]}
            textAlign="center"
            onClick={(e) => {
              navigator.clipboard.writeText(ibanNumberRef.current.innerText);
            }}>
            نسخ
          </Text>
        </Flex>
      </Box>

      <Image src={imgSrc} w="80px" pos="absolute" top="15px" left="15px" />
      <Text pos="absolute" bottom="15px" left="15px">
        شركة فلاة الحلول للخدمات التجارية
      </Text>
    </Box>
  );
};

export default BankAccount;
