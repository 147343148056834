import { Box, Text, Flex, Link } from "@chakra-ui/react";
import { TitleText, Container, HeaderPage } from "components/shared";
import { useTranslation, Trans } from "react-i18next";

const Privacy = () => {
  const { t } = useTranslation();

  const CustomText = ({ children }) => {
    return (
      <Text opacity="0.6" lineHeight="2.1" color="#333" fontSize="14px">
        {children}
      </Text>
    );
  };

  const CircleText = ({ children, ...props }) => {
    return (
      <Flex mb="20px" {...props}>
        <Box
          w="10px"
          h="10px"
          borderRadius="50%"
          border="2px solid #333"
          me="7px"
          mt="8px"
          opacity="0.6"
        />
        <Text
          opacity="0.6"
          lineHeight="2.1"
          color="#333"
          fontSize="14px"
          w="98%">
          {children}
        </Text>
      </Flex>
    );
  };

  const ItalicText = ({ children, ...props }) => {
    return (
      <Text
        fontStyle="italic"
        fontSize="14px"
        fontWeight="semibold"
        color="#333"
        mb="20px"
        {...props}>
        {children}
      </Text>
    );
  };

  return (
    <Box pt="40px">
      <Container>
        <HeaderPage
          name={t("privacy.header")}
          color="#414042"
          letterSpacing="2px"
        />
        <Box mb="50px">
          <CustomText>
            <Trans i18nKey="privacy.intro" />
          </CustomText>
        </Box>

        <TitleText title={t("privacy.scope.title")}>
          <CustomText>
            <Trans i18nKey="privacy.scope.text" />
          </CustomText>
        </TitleText>

        <TitleText title={t("privacy.collection_of_info.title")}>
          <ItalicText>
            {" "}
            <Trans i18nKey="privacy.collection_of_info.text1" />
          </ItalicText>

          <CustomText>
            <Trans i18nKey="privacy.collection_of_info.text2" />
          </CustomText>

          <ItalicText mt="20px">
            {" "}
            <Trans i18nKey="privacy.collection_of_info.text3" />
          </ItalicText>

          <CustomText>
            <Trans i18nKey="privacy.collection_of_info.text4" />
          </CustomText>

          <ItalicText mt="20px">
            {" "}
            <Trans i18nKey="privacy.collection_of_info.text5" />
          </ItalicText>

          <CustomText>
            <Trans i18nKey="privacy.collection_of_info.text6" />
            <br />
            <br />
          </CustomText>

          <CircleText>
            <Trans i18nKey="privacy.collection_of_info.text7" />
          </CircleText>

          <CircleText>
            <Trans i18nKey="privacy.collection_of_info.text8" />
          </CircleText>

          <CircleText>
            <Trans i18nKey="privacy.collection_of_info.text9" />
          </CircleText>

          <CircleText>
            <Trans i18nKey="privacy.collection_of_info.text10" />
          </CircleText>
        </TitleText>

        <TitleText title={t("privacy.personal_data.title")}>
          <CustomText>
            <Trans i18nKey="privacy.personal_data.text1" />
            <br />
            <br />
          </CustomText>

          <CircleText>
            <Trans i18nKey="privacy.personal_data.text2" />
          </CircleText>

          <CircleText>
            <Trans i18nKey="privacy.personal_data.text3" />
          </CircleText>

          <CircleText>
            <Trans i18nKey="privacy.personal_data.text4" />
          </CircleText>

          <CircleText>
            <Trans i18nKey="privacy.personal_data.text5" />
          </CircleText>

          <CircleText>
            <Trans i18nKey="privacy.personal_data.text6" />
          </CircleText>

          <CircleText>
            <Trans i18nKey="privacy.personal_data.text7" />
          </CircleText>

          <CircleText>
            <Trans i18nKey="privacy.personal_data.text8" />
          </CircleText>
        </TitleText>

        <TitleText title={t("privacy.storage.title")}>
          <CustomText>
            <Trans i18nKey="privacy.storage.text1" />
          </CustomText>
        </TitleText>

        <TitleText title={t("privacy.sharing.title")}>
          <CustomText>
            <Trans i18nKey="privacy.sharing.text1" />
          </CustomText>

          <ItalicText mt="20px">
            {" "}
            <Trans i18nKey="privacy.sharing.text2" />
          </ItalicText>

          <CustomText>
            <Trans i18nKey="privacy.sharing.text3" />
          </CustomText>

          <CircleText mt="20px">
            <Trans i18nKey="privacy.sharing.text4" />
          </CircleText>

          <CircleText>
            <Trans i18nKey="privacy.sharing.text5" />
          </CircleText>

          <CircleText>
            <Trans i18nKey="privacy.sharing.text6" />
          </CircleText>

          <CircleText>
            <Trans i18nKey="privacy.sharing.text7" />
          </CircleText>
        </TitleText>

        <TitleText title={t("privacy.other_sharing.title")}>
          <CustomText>
            <Trans i18nKey="privacy.other_sharing.text1" />
          </CustomText>

          <CircleText mt="20px">
            <Trans i18nKey="privacy.other_sharing.text2" />
          </CircleText>

          <CircleText>
            <Trans i18nKey="privacy.other_sharing.text3" />
          </CircleText>

          <CircleText>
            <Trans i18nKey="privacy.other_sharing.text4" />
          </CircleText>

          <CircleText>
            <Trans i18nKey="privacy.other_sharing.text5" />
          </CircleText>

          <CircleText>
            <Trans i18nKey="privacy.other_sharing.text6" />
          </CircleText>
        </TitleText>

        <TitleText title={t("privacy.choices.title")}>
          <ItalicText mt="20px">
            {" "}
            <Trans i18nKey="privacy.choices.text1" />
          </ItalicText>

          <CustomText>
            <Trans i18nKey="privacy.choices.text2" />
          </CustomText>

          <ItalicText mt="20px">
            {" "}
            <Trans i18nKey="privacy.choices.text3" />
          </ItalicText>

          <CustomText>
            <Trans i18nKey="privacy.choices.text4" />
          </CustomText>

          <ItalicText mt="20px">
            {" "}
            <Trans i18nKey="privacy.choices.text5" />
          </ItalicText>

          <CustomText>
            <Trans i18nKey="privacy.choices.text6" />
          </CustomText>
        </TitleText>

        <TitleText title={t("privacy.security.title")}>
          <CustomText>
            <Trans i18nKey="privacy.security.text1" />
          </CustomText>
        </TitleText>

        <TitleText title={t("privacy.cookies.title")}>
          <CustomText>
            <Trans i18nKey="privacy.cookies.text1" />
          </CustomText>
        </TitleText>

        <TitleText title={t("privacy.contactus.title")}>
          <CustomText>
            <Trans i18nKey="privacy.contactus.text1" />
            <Link href="mailto:legal@aerbag.com" color="text.mainColor">
              support@aerbag.app
            </Link>
          </CustomText>
        </TitleText>
      </Container>
    </Box>
  );
};

export default Privacy;
