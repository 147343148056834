import * as Yup from "yup";

export const signupValidation = Yup.object().shape({
  phone: Yup.string().required("هذا الحقل مطلوب"),

  userName: Yup.string().required("هذا الحقل مطلوب"),

  email: Yup.string()
    .email("ايميل غير صالح")
    .max(250, "يجب ان تدخل اقل من 100 احرف")
    .required("هذا الحقل مطلوب"),

  //password: Yup.string().required("هذا الحقل مطلوب"),
});
