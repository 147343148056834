import { useState } from "react";
import { Box, Text, useToast } from "@chakra-ui/react";
import {
  Container,
  CustomInput,
  CustomButton,
  ErrorMsg,
  CustomToast,
} from "components/shared";
import { Formik, Form } from "formik";
import { signinValidation } from "validations/signin";
import { auth, genrateRecaptcha } from "firebase-config";
import { signInWithPhoneNumber } from "firebase/auth";
import { Link, useNavigate, Outlet } from "react-router-dom";
import ConfirmOtp from "./confirm-otp";

const SignIn = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const [isOtp, setIsOtp] = useState(false);
  const [userPhone, setUserPhone] = useState(null);

  const requestOtp = (phoneNumber) => {
    genrateRecaptcha();

    let appVerifier = window.recaptchaVerifier;
    signInWithPhoneNumber(auth, "+966" + phoneNumber, appVerifier)
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        window.confirmationResult = confirmationResult;
        toast({
          render: (props) => {
            return (
              <CustomToast
                title="ارسال رمز التحقق"
                description="تم ارسال رمز التحقق بنجاح "
                status="success"
                onClose={props.onClose}
              />
            );
          },
        });
        // ...
      })
      .catch((error) => {
        // if(error?.data?.errors?.message=="TOO_MANY_ATTEMPTS_TRY_LATER"){
        //   toast({
        //     render: (props) => {
        //       return (
        //         <CustomToast
        //           title={"الكثير من المحاولات"}
        //           description="برجاء المحاولة بعد فترة "
        //           status="error"
        //           onClose={props.onClose}
        //         />
        //       );
        //     },
        //   });
        // }
        toast({
          render: (props) => {
            return (
              <CustomToast
                title={"حدث خطأ"}
                description="حدث خطأ ما برجاء اعادة المحاولة "
                status="error"
                onClose={props.onClose}
              />
            );
          },
        });

        // Error; SMS not sent
        // ...
      });
  };

  if (!isOtp) {
    return (
      <>
        <Box>
          <Container>
            <Text
              fontSize="19px"
              fontWeight="bold"
              color="#405fa1"
              mb="25px"
              textAlign="center"
            >
              تسجيل الدخول
            </Text>

            <Formik
              initialValues={{
                phone: "",
              }}
              validationSchema={signinValidation}
              onSubmit={(values) => {
                requestOtp(values.phone);

                setIsOtp(true);
              }}
            >
              {(formik) => {
                return (
                  <Form>
                    <Box w={["95%", "85%", "60%", ""]} m="auto">
                      <CustomInput
                        label="رقم الجوال"
                        mb="10px"
                        value={formik.values.phone}
                        onChange={formik.handleChange("phone")}
                        onBlur={formik.handleBlur("phone")}
                      />
                      <Text fontSize="12px">مثال : 05xxxxxxxx</Text>
                      {formik.errors.phone && formik.touched.phone && (
                        <ErrorMsg text={formik.errors.phone} />
                      )}
                      <CustomButton
                        name="تسجيل"
                        mt="50px"
                        mb="10px"
                        type="submit"
                      />
                      <Text textAlign="center">
                        {" "}
                        ليس لديك حساب؟
                        <Box
                          as={Link}
                          to="/signup"
                          fontWeight="bold"
                          color="#007bff"
                        >
                          {" "}
                          تسجيل حساب جديد
                        </Box>
                      </Text>
                    </Box>
                  </Form>
                );
              }}
            </Formik>
          </Container>
        </Box>

        <Box id="request-recaptcha"></Box>
      </>
    );
  } else {
    return (
      <>
        <ConfirmOtp userPhone={userPhone} />
        <Box id="request-recaptcha"></Box>
      </>
    );
  }
};

export default SignIn;
