import { Box, Flex, Text, Td, Tr, Grid } from "@chakra-ui/react";
import { Container, Loading } from "components/shared";
import { Table } from "components/shared";
import { useQuery } from "react-query";
import { getMyFavourites } from "apis/faviourte";
import { Link, useNavigate } from "react-router-dom";

const Favorites = () => {
  const { data, isLoading } = useQuery("my-favorites", getMyFavourites);
  const navigate = useNavigate();

  const favoriteData = data?.data?.data;

  if (isLoading) {
    return (
      <Grid minH="60vh" placeItems="center">
        <Loading />
      </Grid>
    );
  }

  return (
    <Container>
      <Box border="1px solid rgba(0,0,0,.125)" borderRadius="5px" pb="20px">
        <Flex
          justifyContent="space-between"
          alignItems="center"
          p="15px"
          bg="rgba(0,0,0,.03)"
          borderBottom="1px solid rgba(0,0,0,.125)"
          mb="20px"
        >
          <Text>المفضلة</Text>
        </Flex>

        <Box px="15px">
          <Table headers={["#", "رقم الاعلان", "اللوحة"]}>
            {favoriteData?.length == 0 ? (
              <Tr bg="#f7f7f7">
                <Td colSpan="3">
                  <Text textAlign="center"> لا يوجد بيانات لعرضها</Text>
                </Td>
              </Tr>
            ) : (
              favoriteData?.map((e) => {
                return (
                  <Tr
                    bg="#f7f7f7"
                    cursor="pointer"
                    transition="0.3s"
                    _hover={{ bg: "#f3f3f3" }}
                    onClick={() => navigate(`/show-adds/${e.id}`)}
                  >
                    <Td></Td>
                    <Td>{e?.serial}</Td>
                    <Td>
                      {e?.chars_ar.toString().replace(/,/g, "  ")}{" "}
                      {e?.numbers.toString().replace(/,/g, "  ")}
                    </Td>
                  </Tr>
                );
              })
            )}
          </Table>
        </Box>
      </Box>
    </Container>
  );
};

export default Favorites;
