import { useState } from "react";
import {
  Box,
  Tr,
  Td,
  Text,
  Flex,
  Grid,
  useToast,
  Switch,
} from "@chakra-ui/react";
import {
  Table,
  CustomModal,
  Loading,
  CustomToast,
  CustomButton,
  Luhah,
  FlexContent,
  Pagination,
} from "components/shared";
import RemoveAdd from "../remove-add-modal";
import AddPriceModal from "./add-priceModal";
import { useQuery, useMutation } from "react-query";
import { myListings, updateList } from "apis/lu7ah";
import { Link } from "react-router-dom";
import { ref } from "yup";
import AddRow from "./add-row";
import Card from "./ad-card";

const Adds = () => {
  const { data, isLoading, refetch } = useQuery("my-listings", myListings);
  const [pageNumber, setPageNumber] = useState(1);

  const handlePageClick = async (data) => {
    setPageNumber(data.selected + 1);
  };

  let numberOfPages = data?.data.meta.total;
  let itemsPerPage = data?.data.meta.per_page;
  let currentPage = data?.data.meta.current_page;
  let itemCount = data?.data.meta.itemCount;
  let totalItems = data?.data.meta.total;

  if (isLoading) {
    return (
      <Grid placeItems="center" minH="40vh">
        <Loading />
      </Grid>
    );
  }
  return (
    <Box>
      {/* <Text fontSize="19px" fontWeight="bold" color="#405fa1" mb="15px">
        اعلاناتى
      </Text> */}

      {data?.data?.data.length > 0 ? (
        <Grid
          gridTemplateColumns={[
            "repeat(1,1fr)",
            "repeat(3,1fr)",
            "repeat(3,1fr)",
            "repeat(3,1fr)",
          ]}
          gap="15px">
          {data?.data?.data?.map((e) => {
            return (
              <Card
                key={Math.random()}
                date={e?.created_at}
                serial={e?.serial}
                id={e?.id}
                is_hidden={e?.is_hidden}
                is_sold={e?.is_sold}
                numbers={e?.numbers}
                chars_ar={e?.chars_ar}
                chars_en={e?.chars_en}
                type={e?.type}
                invoice_url={e?.invoice_url}
                receipt_url={e?.receipt_url}
                commission_paid_at={e?.commission_paid_at}
                refetch={refetch}
              />

              // <AddRow
              //   key={Math.random()}
              //   date={e?.created_at}
              //   serial={e?.serial}
              //   id={e?.id}
              //   is_hidden={e?.is_hidden}
              //   is_sold={e?.is_sold}
              //   numbers={e?.numbers}
              //   chars_ar={e?.chars_ar}
              //   commission_paid_at={e?.commission_paid_at}
              //   refetch={refetch}
              // />
            );
          })}
        </Grid>
      ) : (
        <Box>
          <Text textAlign="center"> لا يوجد بيانات لعرضها</Text>
          <CustomButton
            as={Link}
            to="/add-adds"
            name="قم بأضافة إعلان"
            w="50%"
            m="auto"
            display="flex"
            mt="10px"
          />
        </Box>
      )}

      {/* <Table
        headers={["#", "رقم الاعلان", "التاريخ", "اللوحة", "الحالة", "عمليات"]}>
        {data?.data?.data.length > 0 ? (
          data?.data?.data?.map((e) => {
            return (
              <Grid
                gridTemplateColumns={[
                  "repeat(1,1fr)",
                  "repeat(3,1fr)",
                  "repeat(3,1fr)",
                  "repeat(3,1fr)",
                ]}
                gap="15px">
                <Card
                  key={Math.random()}
                  date={e?.created_at}
                  serial={e?.serial}
                  id={e?.id}
                  is_hidden={e?.is_hidden}
                  is_sold={e?.is_sold}
                  numbers={e?.numbers}
                  chars_ar={e?.chars_ar}
                  chars_en={e?.chars_en}
                  type={e?.type}
                  commission_paid_at={e?.commission_paid_at}
                  refetch={refetch}
                />
              </Grid>

              // <AddRow
              //   key={Math.random()}
              //   date={e?.created_at}
              //   serial={e?.serial}
              //   id={e?.id}
              //   is_hidden={e?.is_hidden}
              //   is_sold={e?.is_sold}
              //   numbers={e?.numbers}
              //   chars_ar={e?.chars_ar}
              //   commission_paid_at={e?.commission_paid_at}
              //   refetch={refetch}
              // />
            );
          })
        ) : (
          <Tr bg="#f7f7f7">
            <Td colSpan="6">
              <Text textAlign="center"> لا يوجد بيانات لعرضها</Text>
              <CustomButton
                as={Link}
                to="/add-adds"
                name="قم بأضافة إعلان"
                w="50%"
                m="auto"
                display="flex"
                mt="10px"
              />
            </Td>
          </Tr>
        )}
      </Table> */}
      {/* <Pagination
        handlePageClick={handlePageClick}
        numberOfPages={numberOfPages}
        itemsPerPage={itemsPerPage}
        currentPage={currentPage}
        itemCount={itemCount}
        totalItems={totalItems}
      /> */}
    </Box>
  );
};

export default Adds;
