import { useRef, useState, useEffect, useContext } from "react";
import {
  Box,
  Flex,
  Button,
  Text,
  Image,
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
} from "@chakra-ui/react";
import { userContext } from "contexts/user";
import { Container } from "components/shared";
import { IoHome } from "react-icons/io5";
import { GoPlus } from "react-icons/go";
import { FaInfo, FaUserAlt, FaUser } from "react-icons/fa";
import { MdLocalPhone } from "react-icons/md";
import { GrMail } from "react-icons/gr";
import { BsFillHeartFill } from "react-icons/bs";
import { FiMenu } from "react-icons/fi";
import { NavLink, Link } from "react-router-dom";
import logo from "assets/images/logo.png";

const Header = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef();
  const user = JSON.parse(localStorage.getItem("lu7ah_user"));
  const { exit } = useContext(userContext);

  let links = [
    { name: "الرئيسية", url: "/", icon: IoHome },
    { name: "حساب العمولة", url: "/commission", icon: FaInfo },
    { name: "تسجيل دخول", url: "/signin", icon: FaUserAlt },
    { name: "اتصل بنا", url: "/contact-us", icon: MdLocalPhone },
  ];

  const authenticatedLinks = [
    { name: "الرئيسية", url: "/", icon: IoHome },
    { name: "حساب العمولة", url: "/commission", icon: FaInfo },
    { name: "اتصل بنا", url: "/contact-us", icon: MdLocalPhone },
  ];

  window.onresize = () => {
    if (window.innerWidth > 991) {
      onClose();
    }
  };

  const LinkNav = ({ name, url, icon }) => {
    const [hover, setHover] = useState(false);

    return (
      <Flex
        alignItems="center"
        justifyContent="center"
        pos="relative"
        // me="30px"
        px="20px"
        minW="80px"
        _after={{
          content: `""`,
          pos: "absolute",
          left: "0",
          w: "1px",
          h: "50%",
          bg: "#31459d2b",
        }}>
        <NavLink
          to={url}
          onMouseOver={() => setHover(true)}
          onMouseLeave={() => setHover(false)}>
          <Flex alignItems="center">
            <Box as={icon} me="5px" color="text.darkBlue" />
            <Text color="text.blackColor">{name}</Text>
          </Flex>
        </NavLink>

        <Box
          pos="absolute"
          h="3px"
          w={hover ? "25%" : "0"}
          transition=".3s"
          transform="translate(-50%,10px)"
          left="50%"
          bg="bg.darkBlue"
          bottom="0"
        />
      </Flex>
    );
  };

  return (
    <>
      <Box boxShadow="0 0 22px 0px #00385e" py="18px" bg="#fbfbfb">
        <Container>
          <Flex justifyContent="space-between" alignItems="center">
            <Flex alignItems="center">
              <Box as={Link} to="/" _hover={{}} _active={{}} _focus={{}}>
                <Image src={logo} w="133px" h="65px" me="45px" />
              </Box>
              <Box display={["none", "", "flex", ""]}>
                {!user || !user?.is_profile_ready
                  ? links.map((e) => {
                    return (
                      <LinkNav
                        key={Math.random()}
                        name={e.name}
                        url={e.url}
                        icon={e.icon}
                      />
                    );
                  })
                  : authenticatedLinks.map((e) => {
                    return (
                      <LinkNav
                        key={Math.random()}
                        name={e.name}
                        url={e.url}
                        icon={e.icon}
                      />
                    );
                  })}
                {user && user?.is_profile_ready && (
                  <Text
                    color="red"
                    ms="10px"
                    cursor="pointer"
                    onClick={() => exit()}>
                    خروج
                  </Text>
                )}
              </Box>
            </Flex>
            <Button
              display={["none", "", "flex", ""]}
              as={Link}
              to="add-adds"
              color="white"
              bg="bg.darkBlue"
              px="50px"
              py="23px"
              fontWeight="normal"
              borderRadius="13px"
              _hover={{}}
              _active={{}}
              _focus={{}}>
              <Box as={GoPlus} me="10px" />
              إضافة إعلان{" "}
            </Button>

            <Box
              as={FiMenu}
              display={["block", "", "none", ""]}
              fontSize="35px"
              cursor="pointer"
              ref={btnRef}
              onClick={onOpen}
            />
          </Flex>
        </Container>
      </Box>
      <Box bg="white" position="sticky" top="0" zIndex="11">
        <Container mt="25px" mb="15px">
          <Flex justifyContent="space-between" color="#aaa" py="15px">
            {user && user?.is_profile_ready ? (
              <Flex
                as={Link}
                to="/user"
                alignItems="center"
                transition="0.2s"
                _hover={{ color: "text.darkBlue" }}>
                <Box as={FaUser} me="5px" />
                {user?.name}
              </Flex>
            ) : (
              <Box as={Link} to="/signin">
                تسجيل دخول
              </Box>
            )}

            {/* <Box as={Link} to="/messages">
              <Box as={GrMail} fontSize="1.5rem" />
            </Box> */}
            <Box as={Link} to="/favorites">
              <Box as={BsFillHeartFill} fontSize="1.5rem" />
            </Box>
          </Flex>
        </Container>
      </Box>

      {/* mobile drawer */}
      <Box display={["block", "", "none", ""]}>
        <Drawer
          isOpen={isOpen}
          placement="right"
          onClose={onClose}
          finalFocusRef={btnRef}
          display={["block", "", "none", ""]}>
          <DrawerOverlay />
          <DrawerContent p="0">
            <DrawerBody p="0">
              <Box as={Link} to="/" _hover={{}} _active={{}} _focus={{}}>
                <Image
                  src={logo}
                  w="100%"
                  h="177px"
                  p="15px"
                  onClick={onClose}
                />
                <Box bg="#f9f9f9">
                  {!user || !user?.is_profile_ready
                    ? links.map((e, i) => {
                      return (
                        <Flex
                          key={i}
                          as={Link}
                          to={e.url}
                          alignItems="center"
                          p="15px"
                          borderBottom="1px solid gainsboro"
                          onClick={onClose}>
                          <Box as={e.icon} me="5px" color="text.darkBlue" />
                          <Text color="text.blackColor">{e.name}</Text>
                        </Flex>
                      );
                    })
                    : authenticatedLinks.map((e, i) => {
                      return (
                        <Flex
                          key={i}
                          as={Link}
                          to={e.url}
                          alignItems="center"
                          p="15px"
                          borderBottom="1px solid gainsboro"
                          onClick={onClose}>
                          <Box as={e.icon} me="5px" color="text.darkBlue" />
                          <Text color="text.blackColor">{e.name}</Text>
                        </Flex>
                      );
                    })}

                  {user && user?.is_profile_ready && (
                    <Flex
                      alignItems="center"
                      p="15px"
                      borderBottom="1px solid gainsboro"
                      onClick={() => {
                        exit();
                        onClose();
                      }}>
                      <Text color="red">خروج</Text>
                    </Flex>
                  )}
                </Box>
                <Box m="15px">
                  <Button
                    as={Link}
                    to="add-adds"
                    color="white"
                    bg="bg.darkBlue"
                    px="50px"
                    py="23px"
                    w="100%"
                    fontWeight="normal"
                    borderRadius="13px"
                    onClick={onClose}
                    _hover={{}}
                    _active={{}}
                    _focus={{}}>
                    <Box as={GoPlus} me="10px" />
                    إضافة إعلان{" "}
                  </Button>
                </Box>
              </Box>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </Box>
    </>
  );
};

export default Header;
